import { Suspense, useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';

import AMAppBar from './AMAppBar';
import { useAppState } from '../state/context/AppStateContext';
import SideBar from './SideBar';
import { Outlet, useNavigate } from 'react-router-dom';
import { Collapse, Typography, useMediaQuery, useTheme } from '@mui/material';
import SideBarForMobile from './SiderBarForMobile';
import { ModalComponent } from './common/modal/ModalComponent';
import ConfirmModal from './common/modal/ConfirmModal';
import { removeAuthStorage } from '../utils';
import Loader from './common/Loader';
import BroadCast from './common/BroadCast-Message/BroadCast';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { user } from '../service';
import ChangeCompanyModal from './company/ChangeCompanyModal';

interface PageWrapperProps {
  children?: React.ReactNode;
}
interface INotificationData {
  title: string;
  message: string;
}
const initialNotificationData = {
  title: '',
  message: '',
};

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  const {
    setAuthStatus,
    SetOpenSide,
    openSide,
    authStatus,
    activeCompanies,
    setActiveCompanies,
    getCompanyList,
  } = useAppState();
  const [dynamicWidth, setDynamicWidth] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openChangeCompanyModal, setOpenChangeCompanyModal] = useState(false);

  const matches = useMediaQuery('(max-width:500px)');
  const theme = useTheme();
  const [notificationData, setNotificationData] = useState<INotificationData>(
    initialNotificationData
  );
  const navigate = useNavigate();
  const openModalFunc = () => {
    setOpenModal(true);
  };
  const handleCloseChangeCompanyModal = () => {
    setOpenChangeCompanyModal(false);
  };

  const handleCloseConfirm = () => {
    setOpenModal(false);
  };
  const Logout = () => {
    const logout: boolean = removeAuthStorage();
    if (logout) {
      setAuthStatus && setAuthStatus(undefined);
      navigate('/login');
    }
  };
  useEffect(() => {
    getCompanyList && getCompanyList();
  }, []);
  useEffect(() => {
    user.getBannerNotification().then((res) => {
      if (!res.status) {
        return;
      }
      setNotificationData(res.data);
    });
  }, []);

  return (
    <Box>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          position: 'fixed',
          px: 2,
          pt: 2,
          transition: '.5s',
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
      >
        <CssBaseline />
        <AMAppBar
          settings={[
            ...(activeCompanies && activeCompanies?.length > 1
              ? [
                {
                  name: 'Change Company',
                  icon: ChangeCircleOutlinedIcon,
                  onClick: () => setOpenChangeCompanyModal(true),
                },
              ]
              : []),
            {
              name: 'Log Out',
              path: setAuthStatus || '',
              icon: LogoutIcon,
              onClick: openModalFunc,
            },
          ]}
          SetOpenSide={SetOpenSide}
        />
        <Box sx={{}}>
          <Box sx={{}}>
            {' '}
            <Collapse
              in={openSide}
              sx={{
                position: 'relative',
                width: '100%',

                top: 0,
                left: 0,
                zIndex: 2,
              }}
            >
              <SideBarForMobile SetOpenSide={SetOpenSide} />
            </Collapse>
          </Box>
          <Collapse in={!openSide}>
            <Box
              sx={{
                display: 'flex',
                height: '100vh',
                position: 'relative',
                transition: '.5s',
                paddingBottom: 13,
                zIndex: 1,
              }}
            >
              {!matches && (
                <Box
                  sx={{
                    minWidth: {
                      xs: '75px',
                      sm: '75px',
                      md: dynamicWidth ? '250px' : '75px',
                    },
                    maxHeight: '100%',
                    position: 'relative',
                    zIndex: '2',
                    transition: '.5s',
                    paddingBottom: 2,
                  }}
                >
                  <SideBar setDynamicWidth={setDynamicWidth} />
                </Box>
              )}

       
          
              <Box
                sx={{
                  px: 2,
                  mt: 4,
                  paddingBottom: matches ? 5 : 0,
                  paddingLeft: !matches ? 3 : 0,
                  paddingRight: !matches ? 3 : 0,
                  transition: '.5s',
                  width: matches ? '100%' : 'calc(100% - 75px)',
                  position: 'relative',
                  zIndex: '1',
                  overflowY: 'auto',
                  borderRadius: '20px 20px 0 0',
                  '&::-webkit-scrollbar': {
                    display: 'inline',
                    width: 5,
                    height: 5,
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: theme.colors?.secondary.default,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.colors?.primary.default,
                    borderRadius: 2,
                  },
                }}
                id='main-body'
              >
                { activeCompanies &&activeCompanies?.length>2 && <Box sx={{position:'sticky',top:'0',zIndex:'1000'}} width={'100%'} >
                  <Box sx={{height:50, borderRadius: '10px',
                    background: theme.colors?.primary.default,display:'flex',alignItems:'center',justifyContent:'center',}}>
                    <Typography fontWeight={600} fontSize={'large'} sx={{color:theme.colors?.secondary.default}}>
                      {authStatus?.companyName}
                    </Typography>
                  </Box>
                  <Box height={30} sx={{background: theme.colors?.secondary.default ,borderBottomRightRadius:'10px',borderBottomLeftRadius:'10px'}}> </Box>
                </Box>}
                <Suspense fallback={<Loader />}>
                  <Outlet />
                </Suspense>
              </Box>
       
            </Box>
            
          </Collapse>
        </Box>
      </Box>
      <ModalComponent
        isClose={handleCloseChangeCompanyModal}
        isOpen={openChangeCompanyModal}
      >
        <ChangeCompanyModal handleClose={handleCloseChangeCompanyModal} />
      </ModalComponent>
      <ModalComponent isOpen={openModal} isClose={handleCloseConfirm}>
        <ConfirmModal
          onSubmit={() => {
            Logout();
          }}
          isClose={handleCloseConfirm}
          message='Are you sure you want to logout?'
          ButtonText='Logout'
        />
      </ModalComponent>
      {!authStatus?.isBannerNotificationShowed && notificationData.message && (
        <BroadCast notificationData={notificationData} />
      )}
    </Box>
  );
};
export default PageWrapper;
